<template lang="pug">
el-dialog.user-rights-popup(
    :title="`Настройка прав менеджера ${manager.email}`"
    :visible="show"
    append-to-body=""
    :before-close="close")
    el-tag.w-full.mb-4(type='warning' v-if="disabledForm") Вы не можете редактировать суперюзера
    el-form(:model="form" ref="ManagerRightsForm" :disabled="disabledForm")
        el-form-item(label="Роль" :prop="RIGHT_PREFIXES.ROLES")
            el-select.w-full(
                v-model="form[RIGHT_PREFIXES.ROLES]" 
                multiple 
                placeholder="Выберите роли" 
                collapse-tags 
                filterable 
                clearable
                :disabled="loading"
            )
                el-option(
                    v-for="role in projectRolesGetter"
                    :key="role.id"
                    :label="role.name"
                    :value="role.id"
                )
        el-form-item(label="Права" :prop="RIGHT_PREFIXES.RIGHTS")
            el-select.w-full(
                v-model="form[RIGHT_PREFIXES.RIGHTS]" 
                multiple 
                placeholder="Выберите права" 
                collapse-tags 
                filterable 
                clearable
                :disabled="loading"
            )
                el-option(
                    v-for="right in projectRightsGetter"
                    :key="right.key"
                    :label="right.title"
                    :value="right.key"
                )
        el-form-item(label="Категории" :prop="RIGHT_PREFIXES.PAYMENT_CATEGORIES")
            CategoriesCascader(
                v-model="form[RIGHT_PREFIXES.PAYMENT_CATEGORIES]" 
                type="manager" 
                size="large" 
                :filters="{project: 'accountancy'}"
                :disabled="loading"
            )

        el-form-item(label="Счета" :prop="RIGHT_PREFIXES.ACCOUNTS")
            AccountsSelect.w-full(
                v-model="form[RIGHT_PREFIXES.ACCOUNTS]" 
                multiple 
                size="large"
                :disabled="loading"
            )

    span.dialog-footer(slot="footer")
        el-button(@click="close" size="small") Отмена
        el-button(
            @click="submit"
            :disabled="loading || disableSubmit || disabledForm" 
            :loading="loading || disableSubmit"
            type="primary"  
            size="small" 
        ) Сохранить

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AccountsSelect from '@/components/ui/AccountsSelect';
import CategoriesCascader from '@/components/ui/CategoriesCascader';
import { RIGHT_PREFIXES } from '@/utils/constants';

export default {
    components: { CategoriesCascader, AccountsSelect },
    data() {
        return {
            RIGHT_PREFIXES,
            show: false,
            disableSubmit: false,
            form: {
                [RIGHT_PREFIXES.RIGHTS]: [],
                [RIGHT_PREFIXES.PAYMENT_CATEGORIES]: [],
                [RIGHT_PREFIXES.ROLES]: [],
                [RIGHT_PREFIXES.ACCOUNTS]: [],
            },
            manager: {},
            managerRights: {},
            loading: false,
            unwatch: null,
        };
    },
    computed: {
        ...mapGetters('relationManager', [
            'projectRightsGetter',
            'categoriesStructureGetter',
            'projectRolesGetter',
        ]),
        ...mapGetters(['authUserAccess']),
        currentManagerIsSuper() {
            return (
                this.managerRights &&
                this.managerRights.rights &&
                this.managerRights.rights.SUPERUSER
            );
        },
        authManagerIsSuper() {
            return (
                this.authUserAccess &&
                this.authUserAccess.rights &&
                this.authUserAccess.rights.SUPERUSER
            );
        },
        disabledForm() {
            return !this.authManagerIsSuper && this.currentManagerIsSuper;
        },
    },
    methods: {
        ...mapActions('relationManager', [
            'createRoleRightsAction',
            'getProjectRolesAction',
            'getProjectRightsAction',
            'getManagerRightsAction',
            'updateRightsAction',
        ]),

        async init(manager) {
            try {
                this.loading = true;
                this.manager = manager;
                this.form.managerId = this.manager.id;
                this.getProjectRightsAction('list');
                this.getProjectRolesAction();
                const managerRights = await this.getManagerRightsAction({
                    managerId: this.manager.id,
                });

                this.managerRights = managerRights.data;

                this.initForm(this.managerRights);
            } finally {
                this.loading = false;
            }
        },

        initForm(rights) {
            if (!rights) return;

            this.clearFields();

            Object.keys(rights).forEach(accessType => {
                if (accessType === RIGHT_PREFIXES.RIGHTS) {
                    this.form[RIGHT_PREFIXES.RIGHTS] = this.form[
                        RIGHT_PREFIXES.RIGHTS
                    ].concat(Object.keys(rights[accessType]));
                }
                if (accessType === RIGHT_PREFIXES.ROLES) {
                    this.form[RIGHT_PREFIXES.ROLES] = this.form[
                        RIGHT_PREFIXES.ROLES
                    ].concat(Object.keys(rights[accessType]).map(i => +i));
                }
                if (accessType === RIGHT_PREFIXES.PAYMENT_CATEGORIES) {
                    Object.entries(
                        rights[RIGHT_PREFIXES.PAYMENT_CATEGORIES],
                    ).forEach(([key]) => {
                        this.form[RIGHT_PREFIXES.PAYMENT_CATEGORIES].push(
                            key.split('.'),
                        );
                    });
                }
                if (accessType === RIGHT_PREFIXES.ACCOUNTS) {
                    this.form[RIGHT_PREFIXES.ACCOUNTS] = this.form[
                        RIGHT_PREFIXES.ACCOUNTS
                    ].concat(
                        Object.keys(rights[accessType]).map(i =>
                            !isNaN(i) ? +i : i,
                        ),
                    );
                }
            });

            this.unwatch = this.$watch(
                `form.${RIGHT_PREFIXES.ROLES}`,
                this.changeRoles,
            );
        },

        open(user) {
            this.show = true;
            this.init(user);
        },

        close() {
            this.show = false;
            this.clearFields();
        },

        clearFields() {
            Object.values(RIGHT_PREFIXES).forEach(key => {
                this.form[key] = [];
            });
            if (this.unwatch) {
                this.unwatch();
            }
        },
        prepareForm() {
            return Object.entries(this.form)
                .reduce((acc, [accessType, rightsList]) => {
                    if (
                        rightsList &&
                        Array.isArray(rightsList) &&
                        rightsList.length
                    ) {
                        acc = acc.concat(
                            rightsList.map(right => {
                                if (accessType === RIGHT_PREFIXES.RIGHTS) {
                                    return {
                                        rightKey: right,
                                        accessType,
                                    };
                                }

                                if (accessType === RIGHT_PREFIXES.ROLES) {
                                    return {
                                        entityId: right,
                                        accessType,
                                    };
                                }

                                if (
                                    accessType ===
                                    RIGHT_PREFIXES.PAYMENT_CATEGORIES
                                ) {
                                    return {
                                        path: right.join('.'),
                                        accessType,
                                    };
                                }

                                if (accessType === RIGHT_PREFIXES.ACCOUNTS) {
                                    if (isNaN(right)) {
                                        return {
                                            rightKey: right,
                                            accessType,
                                        };
                                    } else {
                                        return {
                                            entityId: +right,
                                            accessType,
                                        };
                                    }
                                }

                                return false;
                            }),
                        );
                    }

                    return acc;
                }, [])
                .filter(Boolean);
        },

        async submit() {
            this.$refs.ManagerRightsForm.validate(async valid => {
                if (valid) {
                    this.disableSubmit = true;
                    try {
                        await this.updateRightsAction({
                            managerId: this.form.managerId,
                            rights: this.prepareForm(),
                        });
                        this.$notify({
                            title: 'Успешно',
                            message: 'Права успешно обновлены',
                            type: 'success',
                            duration: 4000,
                        });
                        this.$emit('onSubmit');
                        this.close();
                    } finally {
                        this.disableSubmit = false;
                    }
                }
            });
        },

        clearSelectedRights(roleId) {
            const foundRole = this.projectRolesGetter.find(
                r => +r.id === +roleId,
            );

            if (!foundRole) return;

            const access = foundRole.access || {};

            Object.entries(access).forEach(([type, roleRights]) => {
                if (this.form[type]) {
                    roleRights = roleRights.map(
                        r => r.entity_id || r.path || r.right_key,
                    );
                    this.form[type] = this.form[type].filter(selectedRight => {
                        selectedRight =
                            type === RIGHT_PREFIXES.PAYMENT_CATEGORIES
                                ? selectedRight.join('.')
                                : selectedRight;

                        return !roleRights.includes(selectedRight);
                    });
                }
            });
        },

        addSelectedRights(roleId) {
            const foundRole = this.projectRolesGetter.find(
                r => +r.id === +roleId,
            );

            if (!foundRole) return;

            const access = foundRole.access || {};

            Object.entries(access).forEach(([type, roleRights]) => {
                if (this.form[type]) {
                    roleRights = roleRights.map(
                        r => r.entity_id || r.path || r.right_key,
                    );
                    if (type === RIGHT_PREFIXES.PAYMENT_CATEGORIES) {
                        const map = this.form[type].reduce(
                            (acc, categoryArr) => {
                                const path = categoryArr.join('.');

                                if (!acc[path]) {
                                    acc[path] = true;
                                }

                                return acc;
                            },
                            {},
                        );

                        this.form[type] = this.form[type].concat(
                            roleRights
                                .filter(r => !map[r])
                                .map(r => r.split('.')),
                        );
                    } else {
                        const resultArr = roleRights.filter(
                            r => !this.form[type].includes(r),
                        );

                        this.form[type] = this.form[type].concat(resultArr);
                    }
                }
            });
        },

        changeRoles(n, o) {
            if (n.length > o.length) {
                n.forEach(r => {
                    if (!o.includes(r)) {
                        this.addSelectedRights(r);
                    }
                });
            } else if (!n.length) {
                o.forEach(this.clearSelectedRights);
            } else {
                o.forEach(r => {
                    if (!n.includes(r)) {
                        this.clearSelectedRights(r);
                    }
                });
            }
        },
    },
};
</script>
