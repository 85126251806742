<template lang="pug">
.roles-wrapper(v-loading="projectRolesLoadingGetter")
  .filters.flex
      el-input(v-model="search" size="small" placeholder="Поиск")
      el-button.ml-4(size="small" type="success" @click="openModal('RoleModal')") Добавить
  .constent.mt-4
        el-collapse(v-model="activeCollapse" accordion)
          el-collapse-item(
            v-for="role of filteredRoles" 
            :title="role.name" 
            :name="role.id"
            :key="role.id" 
            :disabled="!Object.keys(role.access).length"
          )
            template(slot="title")
                .flex.justify-between.items-center.w-full
                  .flex.items-center
                    .title {{ role.name }}
                    el-tag.ml-4(type="danger" size="mini" v-if="!Object.keys(role.access).length") Не настроено
                  el-button.mr-6(
                    size="mini" type="warning" 
                    plain @click.stop="openModal('RoleRightsModal', role)"
                  ) Настроить
            .flex(v-if="Object.keys(role.access).length")
              el-card.card-info(v-for="(rights, key) in role.access" :key="key")
                .flex.bold {{ RIGHT_PREFIXES_TRANSLATES[key] }}
                .flex(v-for="right of rights" 
                    :key="right.right_key" 
                    v-if="key === RIGHT_PREFIXES.RIGHTS"
                ) {{ right.name }}
                .flex(v-for="right of rights" 
                    :key="right.path" 
                    v-if="key === RIGHT_PREFIXES.PAYMENT_CATEGORIES"
                ) {{ right.name }}
                .flex.justify-between(v-for="right of rights" 
                    :key="right.entity_id || right.right_key" 
                    v-if="key === RIGHT_PREFIXES.ACCOUNTS"
                )
                    .flex.items-center {{ getAccountObject(right).name }}
                    .flex.items-center {{ getAccountObject(right).currency }}

  RoleModal(ref="RoleModal" @onSubmit="init")
  RoleRightsModal(ref="RoleRightsModal" @onSubmit="init")
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { RIGHT_PREFIXES_TRANSLATES, RIGHT_PREFIXES } from '@/utils/constants';

import RoleModal from './modals/RoleModal.vue';
import RoleRightsModal from './modals/RoleRightsModal.vue';

export default {
    components: {
        RoleModal,
        RoleRightsModal,
    },
    data() {
        return {
            RIGHT_PREFIXES_TRANSLATES,
            RIGHT_PREFIXES,
            activeCollapse: '',
            search: '',
        };
    },

    computed: {
        ...mapGetters('relationManager', [
            'projectRolesGetter',
            'projectRolesLoadingGetter',
        ]),
        ...mapGetters('accounts', ['accountsMapGetter']),
        ...mapGetters(['merchantsMapGetter']),
        filteredRoles() {
            if (!this.projectRolesGetter) return [];

            return this.projectRolesGetter.filter(role =>
                role.name.includes(this.search),
            );
        },
    },
    methods: {
        ...mapActions('relationManager', ['getProjectRolesAction']),
        init() {
            this.getProjectRolesAction();
        },
        openModal(modal, data) {
            this.$refs[modal].open(data);
        },
        getAccountObject(right) {
            if (right.entity_id)
                return this.accountsMapGetter[right.entity_id] || {};
            if (right.right_key) {
                return this.merchantsMapGetter[right.right_key] || {};
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-info {
    min-width: 32%;
    max-width: 32%;
    margin-right: 5px;
    &:last-child {
        margin-right: 0;
    }
}
</style>
