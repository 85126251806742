/**
 * @author feod0r
 */

export const DEFAULT_CURRENCY = 'RUB';
export const CURRENCIES = {
    RUB: 'RUB',
    USD: 'USD',
    EUR: 'EUR',
    BTC: 'BTC',
    USDT: 'USDT',
    UAH: 'UAH',
    AMD: 'AMD',
    INR: 'INR',
    BRL: 'BRL',
    KZT: 'KZT',
    RSD: 'RSD',
    UZS: 'UZS',
    TJS: 'TJS',
    TRY: 'TRY',
    NGN: 'NGN',
    AZN: 'AZN',
    KES: 'KES',
    MXN: 'MXN',
    ZAR: 'ZAR',
    PLN: 'PLN',
    AED: 'AED',
    GHS: 'GHS',
    EGP: 'EGP',
    MMK: 'MMK',
    JPY: 'JPY',
    ETH: 'ETH',
    ARS: 'ARS',
    TON: 'TON',
    viTON: 'viTON',
    BDT: 'BDT',
    XOF: 'XOF',
    THB: 'THB',
    MYR: 'MYR',
    VND: 'VND',
    VES: 'VES',
    GEL: 'GEL',
};

export const CURRENCY_CHAR_MAP = {
    [CURRENCIES.RUB]: '₽',
    [CURRENCIES.USD]: '$',
    [CURRENCIES.EUR]: '€',
    [CURRENCIES.BTC]: '₿',
    [CURRENCIES.USDT]: '₮',
    [CURRENCIES.UAH]: '₴',
    [CURRENCIES.AMD]: '֏',
    [CURRENCIES.INR]: '₹',
    [CURRENCIES.BRL]: 'R$',
    [CURRENCIES.KZT]: '₸',
    [CURRENCIES.RSD]: 'din',
    [CURRENCIES.UZS]: 'сум',
    [CURRENCIES.TJS]: 'SM',
    [CURRENCIES.TRY]: '₺',
    [CURRENCIES.NGN]: '₦',
    [CURRENCIES.AZN]: '₼',
    [CURRENCIES.KES]: 'KSh',
    [CURRENCIES.MXN]: 'MXN',
    [CURRENCIES.ZAR]: 'R',
    [CURRENCIES.PLN]: 'zł',
    [CURRENCIES.AED]: 'Dhs',
    [CURRENCIES.GHS]: '₵',
    [CURRENCIES.EGP]: 'E£',
    [CURRENCIES.MMK]: 'Ks',
    [CURRENCIES.JPY]: '¥',
    [CURRENCIES.ETH]: 'ETH',
    [CURRENCIES.ARS]: 'ARS',
    [CURRENCIES.TON]: 'TON',
    [CURRENCIES.viTON]: 'viTON',
    [CURRENCIES.XOF]: 'CFA',
    [CURRENCIES.THB]: '฿',
    [CURRENCIES.MYR]: 'RM',
    [CURRENCIES.VND]: '₫',
    [CURRENCIES.VES]: 'Bs.',
    [CURRENCIES.GEL]: '₾',
};
export const CURRENCY_LIST = Object.keys(CURRENCY_CHAR_MAP);

export const CATEGORY_TYPE_MAP = {
    income: 'Доход',
    expense: 'Расход',
};
export const CATEGORY_TYPE_LIST = ['income', 'expense'];

export const GROUP_KEY = 'group_key';

export const PAYOUT_TYPES = {
    income: 'Доход',
    expense: 'Расход',
    transferTo: 'Получено',
    transferFrom: 'Списано',
};

export const LOCAL_STORAGE_TRANSACTIONS_FILTERS_KEY = 'transactions-filter';

export const RIGHT_PREFIXES = {
    RIGHTS: 'rights',
    PAYMENT_CATEGORIES: 'payment-categories',
    ROLES: 'roles',
    ACCOUNTS: 'accounts',
};

export const RIGHT_PREFIXES_TRANSLATES = {
    [RIGHT_PREFIXES.RIGHTS]: 'Права',
    [RIGHT_PREFIXES.PAYMENT_CATEGORIES]: 'Категории',
    [RIGHT_PREFIXES.ROLES]: 'Роли',
    [RIGHT_PREFIXES.ACCOUNTS]: 'Счета',
};

export const STRUCTURE_TYPES = {
    DEPARTMENT: 'department',
    PAYMENT_CATEGORY: 'payment-category',
};

export const STRUCTURE_TYPES_TRANSLATES = {
    [STRUCTURE_TYPES.DEPARTMENT]: 'Отдел',
    [STRUCTURE_TYPES.PAYMENT_CATEGORY]: 'Категория выплат',
};

export const AUTHENTICATOR_SYNCHRONIZATION_TIP =
    'Проверьте дату, время и часовой пояс на устройстве. При необходимости, синхронизируйте их с интернетом.';

export const EXCEPTION_TYPES_FOR_STATUS = {
    302: 'warning',
};
