<template lang="pug">
.step-one
    .flex.items-center.flex-col
        h2.mt-12.max-w-xs.text-center.font-extrabold.text-2xl Загрузите и установите приложение Authenticator

        .flex.w-60.justify-between.links-block
            el-popover.py-16.download-button(placement="left" trigger="hover")
                qrcode-vue(:value="storeLinks.iOS" :size="200" level="M")
                a.no-underline.cursor-pointer(slot="reference" :href="storeLinks.iOS" target="_blank")
                    .store-icon.apple.flex.flex-col.items-center
                        img.mb-6(src="@/assets/store-apple.svg")
                        span.text-sm.mb-1.opacity-70 Скачать из
                        span.font-semibold App Store

            el-popover.py-16.download-button(placement="right" trigger="hover")
                qrcode-vue(:value="storeLinks.android" :size="200" level="M")
                a.no-underline(slot="reference" :href="storeLinks.android" target="_blank")
                    .store-icon.google.flex.flex-col.items-center
                        img.mb-6(src="@/assets/store-google.svg")
                        span.text-sm.mb-1.opacity-70 Скачать из
                        span.font-semibold Google Play

        el-button.w-64(type="primary" @click="$emit('nextStep')") Продолжить
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
    components: { QrcodeVue },
    data() {
        return {
            storeLinks: {
                iOS:
                    'https://apps.apple.com/ru/app/google-authenticator/id388497605',
                android:
                    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
            },
        };
    },
};
</script>

<style lang="scss" scoped>
h2 {
    color: #000;
}

.links-block a {
    color: black;
}

.qr {
    height: 200px;
    width: 200px;
}

.download-button:hover {
    cursor: pointer;
    position: relative;
    top: -2px;
}
</style>
