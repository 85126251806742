<template lang="pug">
  .login
        el-card
            template(v-if="step === STEPS.LOGIN_FORM")
                h2 Авторизация
                el-form.login-form(
                    :model='model',
                    :rules='rules',
                    ref='form',
                    @submit.native.prevent='login'
                )
                    el-form-item(prop='username')
                        el-input.w-full(
                            v-model='model.username',
                            placeholder='Username',
                            prefix-icon='el-icon-user'
                        )
                    el-form-item.mb-0(prop='password')
                        el-input.w-full(
                            v-model='model.password',
                            placeholder='Password',
                            type='password',
                            prefix-icon='el-icon-unlock'
                        )
                    el-form-item
                        .passcode(v-if="needAuthentication")
                            el-divider.my-4
                            PasscodeInput(:update-code="updateCode" :submit="login" ref="passcode")
                            .text-xs.mt-4(v-if="synchronizationTip") {{ synchronizationTip }}
                            .text-xs.mt-4.opacity-70(v-else) Введите код из приложения
                    el-form-item.mb-0
                        el-button.login-button(
                            :loading='loading',
                            type='primary',
                            native-type='submit',
                            block=''
                        ) Войти

            template(v-else-if="step === STEPS.GROUP_FORM")
                h2 Группа
                el-form.login-form(
                    :model="{ group:this.$store.getters.groupId }"
                    ref="groupForm"
                    :rules="groupRules"
                    @submit.native.prevent="groupSubmit"
                )
                    el-form-item(prop="group")
                        GroupSelect
                    el-form-item.mb-0
                        el-button.login-button(
                            type='primary',
                            native-type='submit',
                            block=''
                        ) Продолжить
</template>

<script>
import FingerprintJs from '@fingerprintjs/fingerprintjs';
import FingerprintJsPro from '@fingerprintjs/fingerprintjs-pro';
import { mapGetters, mapActions } from 'vuex';

import GroupSelect from '@/components/GroupSelect';
import {
    GROUP_KEY,
    AUTHENTICATOR_SYNCHRONIZATION_TIP,
} from '@/utils/constants';
import Notify from '@/utils/notify';

import PasscodeInput from './ui/PasscodeInput';

const STEPS = {
    LOGIN_FORM: 1,
    GROUP_FORM: 2,
};

export default {
    name: 'Login',
    components: { GroupSelect, PasscodeInput },
    data: () => ({
        model: { username: '', password: '', authenticationCode: '' },
        step: STEPS.LOGIN_FORM,
        STEPS,
        needAuthentication: false,
        synchronizationTip: '',
        loading: false,
        rules: {
            username: [
                {
                    required: true,
                    message: 'Username is required',
                    trigger: 'blur',
                },
                {
                    min: 4,
                    message: 'Username length should be at least 5 characters',
                    trigger: 'blur',
                },
            ],
            password: [
                {
                    required: true,
                    message: 'Password is required',
                    trigger: 'blur',
                },
                {
                    min: 5,
                    message: 'Password length should be at least 5 characters',
                    trigger: 'blur',
                },
            ],
        },
        groupRules: {
            group: [
                {
                    required: true,
                    message: 'Select group',
                    trigger: 'blur',
                },
            ],
        },
    }),
    beforeMount() {
        this.$store.commit('setGroupValue', null);
    },
    computed: {
        ...mapGetters(['groupId']),
    },

    methods: {
        ...mapActions(['loadGroupList', 'initialLoad', 'authorization']),

        async login() {
            if (this.loading || !(await this.$refs.form.validate())) {
                return;
            }

            if (this.needAuthentication && !this.model.authenticationCode) {
                return;
            }

            this.loading = true;

            // const fingerprint = this.$isprod ? await this.getVisitorData() : ''
            const fingerprint = await this.getVisitorData();

            try {
                const response = await this.authorization({
                    ...this.model,
                    fingerprint,
                });

                if (response === 'authentication_required') {
                    this.needAuthentication = true;
                    return;
                }

                if (!localStorage.getItem(GROUP_KEY)) {
                    this.loadGroupList();
                    this.step = STEPS.GROUP_FORM;
                } else {
                    this.proceed();
                }
            } catch (error) {
                if (error.response.status === 403) {
                    this.synchronizationTip = AUTHENTICATOR_SYNCHRONIZATION_TIP;
                    this.$refs.passcode.resetFields();
                    this.$refs.passcode.$el.classList.add('auth-error');
                }
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        proceed() {
            this.initialLoad();
        },
        async groupSubmit() {
            if (!(await this.$refs.groupForm.validate())) {
                return;
            }
            this.proceed();
        },
        updateCode(value) {
            this.model.authenticationCode = value;
        },
        async getVisitorData() {
            let visitorId;

            for (const instance of [FingerprintJs, FingerprintJsPro]) {
                const fpPromise = instance.load({
                    apiKey: 'Qu5EAwGT9M8zN0ECbupP',
                });

                const fp = await fpPromise.catch(() =>
                    Notify.warning({
                        title: 'Внимание',
                        message:
                            'Отключите AdBlock на странице\nDisable AdBlock on that page',
                    }),
                );

                if (!fp) continue;

                const result = await fp
                    .get()
                    .catch(() => ({ visitorId: null }));

                visitorId = result.visitorId;
            }

            return visitorId;
        },
    },
};
</script>

<style lang="scss">
$teal: rgb(0, 124, 137);

.login {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background: #102a43;
    background-image: url('../assets/LVs7-dots.png');
    background-size: contain;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button--primary {
        width: 100%;
        background: $teal;
        border-color: $teal;

        &:hover,
        &.active,
        &:focus {
            background: lighten($teal, 7);
            border-color: lighten($teal, 7);
        }
    }

    .login .el-input__inner:hover {
        border-color: $teal;
    }

    .el-card {
        padding-top: 0;
        padding-bottom: 10px;
        width: 300px;
        display: flex;
        justify-content: center;
    }

    .el-card__body {
        width: 100%;
    }

    h2 {
        letter-spacing: 1px;
        padding-bottom: 20px;
    }

    a {
        color: $teal;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            color: lighten($teal, 7);
        }
    }
}
</style>
