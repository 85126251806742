import api from '@/utils/api';

const state = {
    connectionUrl: null,
};

const actions = {
    async enableAuthenticatorAction({ commit }) {
        const connectionUrl = await api
            .get('/authenticator/enable-authenticator')
            .then(res => res.data);

        commit('SET_CONNECTION_URL', connectionUrl);

        return connectionUrl;
    },
    async confirm2faAction({ commit, dispatch }, payload) {
        await api.post('/authenticator/confirm-2fa', payload);

        commit('SET_CONNECTION_URL', null);

        // Обновляем информацию о текущем юзере с помощью экшена из корневого модуля
        await dispatch('fetchSystemInfo', null, { root: true });
    },
    async reset2faAction(_ctx, payload) {
        await api.post('/authenticator/reset-2fa', payload);
    },
    async updateDemandKeyAction(_ctx, payload) {
        await api.patch('/authenticator/demand-key', payload);
    },
};

const mutations = {
    SET_CONNECTION_URL(state, value) {
        state.connectionUrl = value;
    },
};

const getters = {
    secretCodeGetter(state) {
        return state.connectionUrl ? state.connectionUrl.split('=')[1] : '';
    },
};

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
};
