<template lang="pug">
el-dialog.user-rights-popup(
    title="Добавить роль"
    @opened="clearFields"
    :visible="show"
    append-to-body=""
    :before-close="close")

    el-form(:model="form" :rules="rules" ref="RoleForm")
        el-form-item(label="Название" prop="name")
            el-input(v-model="form.name")

    span.dialog-footer(slot="footer")
        el-button(@click="close" size="small") Отмена
        el-button(type="primary" @click="submit" size="small") Сохранить

</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            show: false,
            mode: 'create',
            form: {
                name: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Введите название роли',
                        trigger: ['change', 'blur'],
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions('relationManager', ['createRoleAction']),

        open() {
            this.show = true;
        },
        close() {
            this.show = false;
            this.clearFields();
        },
        clearFields() {
            this.$refs.RoleForm.resetFields();
            this.$refs.RoleForm.clearValidate();
        },
        async submit() {
            this.form.name = this.form.name.trim();
            this.$refs.RoleForm.validate(async valid => {
                if (valid) {
                    await this.createRoleAction(this.form);

                    this.$notify({
                        title: 'Успешно',
                        message: 'Роль успешно добавлена',
                        type: 'success',
                        duration: 4000,
                    });

                    this.$emit('onSubmit');
                    this.close();
                }
            });
        },
    },
};
</script>

<style lang="scss"></style>
