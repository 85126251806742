<template lang="pug">
.filters-wrapper
    .actions-wrapper
        el-badge.button(:value="filterCount" type='success' :hidden="!filterCount")
            el-button(@click="showModal('FiltersDrawer')" size="mini") Фильтры
        el-button(
            @click="exportExcelHandler()" 
            size="mini" 
            :loading="loadingExport" 
            v-if="isAllowed('ACCESS_EXPORT_EXCEL')"
        ) Экспорт
        el-button(
            @click="exportExcelHandler('staff')" 
            size="mini" 
            :loading="loadingExport" 
            v-if="isAllowed('ACCESS_EXPORT_EXCEL')"
        ) Экспорт ФОТ
        //- el-button(@click="showModal('ImportExcelForm')" size="mini") Импорт в формате Excel
    FiltersDrawer(ref="FiltersDrawer")
    //- ImportExcelForm(ref="ImportExcelForm")
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
// eslint-disable-next-line import/no-unresolved
import { saveAs } from 'file-saver';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import ImportExcelForm from '@/components/tools/ImportExcelForm';

import FiltersDrawer from './FiltersDrawer';

export default {
    name: 'TransactionFilter',
    components: { ImportExcelForm, FiltersDrawer },
    data: () => ({
        loadingExport: false,
    }),
    computed: {
        ...mapGetters(['transactionDataFilters']),
        filterCount() {
            return Object.values(this.transactionDataFilters).filter(value => {
                return Array.isArray(value) ? value.length : !!value;
            }).length;
        },
    },
    methods: {
        ...mapActions(['exportExcel', 'exportChecker']),
        async checkExport({ source, fileName }) {
            return new Promise(async r => {
                const data = await this.exportChecker({
                    source,
                    fileName,
                });

                if (data.isProcessed) {
                    setTimeout(
                        () =>
                            r(
                                this.checkExport({
                                    source,
                                    fileName: data.fileName,
                                }),
                            ),
                        5000,
                    );
                } else {
                    r(data);
                }
            });
        },
        async exportExcelHandler(source) {
            try {
                this.loadingExport = true;

                const data = await this.checkExport({ source });

                const fileName = data.fileName;

                if (fileName) {
                    const file = await this.exportExcel({
                        fileName,
                    });
                    saveAs(
                        file,
                        `Operations-${moment().format('DD-MM-YYYY')}.xlsx`,
                    );
                }
            } finally {
                this.loadingExport = false;
            }
        },
        showModal(modal) {
            this.$refs[modal].open();
        },
    },
};
</script>

<style scoped lang="scss">
.filters-wrapper {
    display: flex;
    margin-top: 20px;
}

.actions-wrapper {
    display: flex;
    align-items: center;
}

.button {
    margin-right: 10px !important;
}
</style>
