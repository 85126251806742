<template lang="pug">
el-dialog.user-rights-popup(
    title="Настройка роли"
    :visible="show"
    append-to-body=""
    :before-close="close")
    el-form(:model="form" ref="RoleRightsForm")
        el-form-item(label="Роль" prop="roleId")
            el-input(v-model="form.name")
        el-form-item(label="Права" :prop="RIGHT_PREFIXES.RIGHTS")
            el-select.w-full(
                v-model="form[RIGHT_PREFIXES.RIGHTS]" 
                multiple placeholder="Выберите права" 
                collapse-tags 
                filterable 
                clearable
            )
                el-option(
                    v-for="right in projectRightsWithoutSuperGetter"
                    :key="right.key"
                    :label="right.title"
                    :value="right.key"
                )
        el-form-item(label="Категории" :prop="RIGHT_PREFIXES.PAYMENT_CATEGORIES")
            CategoriesCascader(
                v-model="form[RIGHT_PREFIXES.PAYMENT_CATEGORIES]" 
                type="manager" 
                size="large" 
                :filters="{project:'accountancy'}"
            )

        el-form-item(label="Счета" :prop="RIGHT_PREFIXES.ACCOUNTS")
            AccountsSelect.w-full(v-model="form[RIGHT_PREFIXES.ACCOUNTS]" multiple)

    span.dialog-footer(slot="footer")
        el-button(@click="close" size="small") Отмена
        el-button(
            type="primary" 
            @click="submit" 
            size="small" 
            :disabled="disableSubmit" 
            :loading="disableSubmit"
        ) Сохранить

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AccountsSelect from '@/components/ui/AccountsSelect';
import CategoriesCascader from '@/components/ui/CategoriesCascader';
import { RIGHT_PREFIXES, STRUCTURE_TYPES } from '@/utils/constants';

export default {
    components: { CategoriesCascader, AccountsSelect },
    data() {
        return {
            STRUCTURE_TYPES,
            RIGHT_PREFIXES,
            show: false,
            disableSubmit: false,
            form: {
                [RIGHT_PREFIXES.RIGHTS]: [],
                [RIGHT_PREFIXES.PAYMENT_CATEGORIES]: [],
                [RIGHT_PREFIXES.ACCOUNTS]: [],
                name: '',
            },
            role: {},
        };
    },
    computed: {
        ...mapGetters('relationManager', [
            'projectRightsWithoutSuperGetter',
            'categoriesStructureGetter',
        ]),
    },
    methods: {
        ...mapActions('relationManager', [
            'createRoleRightsAction',
            'getRoleRightsAction',
            'getProjectRightsAction',
        ]),

        async init(role) {
            this.role = role;
            this.form.roleId = this.role.id;
            this.form.name = this.role.name;
            this.getProjectRightsAction('list');
            const info = await this.getRoleRightsAction(this.role.id);

            this.form[RIGHT_PREFIXES.PAYMENT_CATEGORIES] =
                info.data[RIGHT_PREFIXES.PAYMENT_CATEGORIES];
            this.form[RIGHT_PREFIXES.RIGHTS] = info.data[RIGHT_PREFIXES.RIGHTS];
            this.form[RIGHT_PREFIXES.ACCOUNTS] =
                info.data[RIGHT_PREFIXES.ACCOUNTS];
        },

        open(role) {
            this.show = true;
            this.init(role);
        },
        close() {
            this.show = false;
            this.clearFields();
        },
        clearFields() {
            this.$refs.RoleRightsForm.resetFields();
            this.$refs.RoleRightsForm.clearValidate();
        },
        async submit() {
            this.$refs.RoleRightsForm.validate(async valid => {
                if (valid) {
                    try {
                        this.disableSubmit = true;
                        await this.createRoleRightsAction(this.form);

                        this.$notify({
                            title: 'Успешно',
                            message: 'Роль успешно настроена',
                            type: 'success',
                            duration: 4000,
                        });
                        this.$emit('onSubmit');
                        this.close();
                    } finally {
                        this.disableSubmit = false;
                    }
                }
            });
        },
    },
};
</script>
