<template lang="pug">
.create-transfer-form
  el-dialog(
    title="Создание перевода",
    :visible="isVisible",
    @close="$emit('updateVisibleStatus', false)",
    @open="resetForm",
    :close-on-click-modal="false",
    :destroy-on-close="true"
  )
    el-form(:model="form", :rules="rules", ref="form")
      el-form-item(prop="accountId", label="Счет списания")
        AccountsSelect.select(v-model="form.accountId" size="large" :multiple="false")

      el-form-item(prop="targetAccountId", label="Счет получения")
        AccountsSelect.select(v-model="form.targetAccountId" size="large" :multiple="false")

      .group
        el-form-item.flex-item(prop="amount", label="Сумма перевода")
          .flex.items-center.justify-stretch.w-full.pt-2
              el-input-number.w-full(
                @change="getConvertedAmount('amount')",
                v-model="form.amount"
                :step="1"
                placeholder="Укажите сумму"
                autocomplete="off"
              )
              .tag.ml-2(size="medium") {{ currency.char }}
        el-form-item.align(
          v-if="currencyTarget.char !== currency.char",
          prop="converted",
        )
          .flex.items-center.justify-stretch.w-full.pt-2
            el-input-number.w-full(
              v-model="form.converted.amount"
              :step="1"
              placeholder="Укажите сумму"
              autocomplete="off"
            )
            .tag.ml-2(size="medium") {{ currencyTarget.char }}

      .group
        el-form-item.flex-item(prop="fee", label="Комиссия за перевод (не обязательно)")
            .flex.items-center.justify-stretch.w-full.pt-2
              el-input-number.w-full(
                @change="getConvertedAmount('fee')",
                v-model="form.fee"
                :step="1"
                placeholder="Укажите комиссию"
                autocomplete="off"
              )
              .tag.ml-2(size="medium") {{ currency.char }}
        el-form-item.align(
          v-if="currencyTarget.char !== currency.char",
          prop="converted",
        )
          .flex.items-center.justify-stretch.w-full.pt-2
            el-input-number.w-full(
              v-model="form.converted.fee"
              :step="1"
              placeholder="Укажите сумму"
              autocomplete="off"
            )
            .tag.ml-2(size="medium") {{ currencyTarget.char }}

      el-form-item(
        prop="date"
        label='Дата операции'
      )
        el-date-picker(
          style="width:100%"
          v-model="form.date"
          type="date"
          placeholder="Выберите дату"
        )

      el-form-item(prop="comment", label="Комментарий")
        el-input(
          v-model="form.comment",
          type="textarea",
          :rows="2",
          placeholder="Комментарий (не обязательно)",
          autocomplete="off"
        )

    span.dialog-footer(slot="footer")
      el-button(
        @click="$emit('updateVisibleStatus', false)",
        :disabled="isLoading"
      ) Отмена
      el-button(
        type="primary",
        :loading="isLoading",
        @click="submitTransaction"
      ) Подтвердить
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import AccountsSelect from '@/components/ui/AccountsSelect';
import api from '@/utils/api';
import { CURRENCY_CHAR_MAP, DEFAULT_CURRENCY } from '@/utils/constants';

const initFormData = () => ({
    accountId: undefined,
    targetAccountId: undefined,
    amount: 0,
    fee: 0,
    comment: '',
    date: null,
    converted: { amount: 0, fee: 0 },
});

export default {
    name: 'CreateTransferForm',
    components: { AccountsSelect },
    props: { isVisible: Boolean },
    data: () => ({
        isLoading: false,
        form: initFormData(),

        rules: {
            accountId: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            targetAccountId: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            amount: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
            fee: {
                required: true,
                message: 'Заполните поле',
                trigger: 'change',
            },
        },
        CURRENCY_CHAR_MAP,
    }),
    computed: {
        ...mapGetters(['transactionData', 'groupId', 'merchantsMapGetter']),
        ...mapGetters('accounts', ['accountsMapGetter']),
        currency() {
            const currency = this.findCurrency('accountId');

            return { currency, char: CURRENCY_CHAR_MAP[currency] || currency };
        },
        currencyTarget() {
            const currency = this.findCurrency('targetAccountId');

            return { currency, char: CURRENCY_CHAR_MAP[currency] || currency };
        },
    },
    methods: {
        ...mapActions([
            'loadTransactionData',
            'loadPropertiesData',
            'utilsConvertCurrency',
        ]),
        findCurrency(prop) {
            let value = this.form[prop];
            if (!value) return DEFAULT_CURRENCY;

            const obj = isNaN(value)
                ? this.merchantsMapGetter[value]
                : this.accountsMapGetter[value];

            let currency = DEFAULT_CURRENCY;
            if (obj && obj.currency) {
                if (obj.name.startsWith('metacash')) {
                    const [currencyName] = obj.currency.split('-');
                    currency = currencyName;
                } else {
                    currency = obj.currency;
                }
            }
            return currency;
        },
        async getConvertedAmount(type) {
            const converted = await this.utilsConvertCurrency({
                amount: this.form[type],
                from: this.currency.currency,
                to: this.currencyTarget.currency,
            });
            this.form.converted[type] = converted;
        },
        resetForm() {
            this.form = initFormData();
        },
        submitTransaction() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.isLoading = true;
                    try {
                        await api.post('/transaction/transfer', {
                            ...this.form,
                            date: this.form.date
                                ? moment(this.form.date).format('YYYY-MM-DD')
                                : null,
                            amount: this.form.amount,
                            groupId: this.groupId,
                        });

                        this.$notify({
                            title: 'Успешно',
                            message: 'Транзакция создана',
                            type: 'success',
                            duration: 3000,
                        });

                        this.$emit('updateVisibleStatus', false);

                        if (this.isAllowed('ACCESS_OPERATIONS')) {
                            this.loadTransactionData(
                                this.transactionData.currentPage,
                            );
                        }

                        this.loadPropertiesData();
                    } finally {
                        this.isLoading = false;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.group {
    display: flex;
    justify-content: stretch;
    .flex-item {
        flex-basis: 100%;
    }
    // flex-basis: auto !important;
    .align {
        flex-basis: 250px;
        margin-top: 21px;
        margin-left: 5px;
    }
}
.create-transfer-form {
    .select {
        width: 100%;
    }

    .el-form-item {
        margin-bottom: 15px;
    }

    .el-form-item__label {
        margin-bottom: 0;
        padding-bottom: 0;
        line-height: inherit;
    }
}

.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    font-size: 12px;
    background: #c0c0c0;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    background-color: #f5f7fa;
    color: #909399;
}
</style>
