import { render, staticRenderFns } from "./AdminRightsPopup.vue?vue&type=template&id=6de32feb&lang=pug"
import script from "./AdminRightsPopup.vue?vue&type=script&lang=js"
export * from "./AdminRightsPopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports