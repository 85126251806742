import api from '@/utils/api';

const MUTATION_KEYS = {
    SET_LOADING: 'SET_LOADING',
    SET_PROP: 'SET_PROP',
};

const state = {
    users: {
        data: null,
        loading: false,
    },
};
const actions = {
    async getUsersAction({ commit }) {
        const prop = 'users';
        commit(MUTATION_KEYS.SET_LOADING, { prop, value: true });

        const users = await api.get('/users');

        commit(MUTATION_KEYS.SET_PROP, { prop, value: users.data });

        commit(MUTATION_KEYS.SET_LOADING, { prop, value: false });

        return users.data;
    },

    async createUserAction(_, form) {
        const users = await api.post('/users', form);

        return users.data;
    },

    async removeUserAction(_, userId) {
        await api.delete(`/users/${userId}`);
    },
};
const mutations = {
    [MUTATION_KEYS.SET_LOADING](state, { prop, value }) {
        state[prop].loading = value;
    },
    [MUTATION_KEYS.SET_PROP](state, { prop, value }) {
        state[prop].data = value;
    },
};
const getters = {
    usersGetter(state) {
        return state.users.data ? state.users.data.data : [];
    },
    usersLoadingGetter(state) {
        return state.users.loading;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
