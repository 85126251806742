/**
 * @author feod0r
 */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { Message } from 'element-ui';
import qs from 'qs';

import store from '@/store/store';
import { EXCEPTION_TYPES_FOR_STATUS } from '@/utils/constants';

import errors from './errors';

export const TOKEN_KEY = 'token';

const instance = axios.create({
    baseURL: '/api',
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

instance.interceptors.request.use(
    config => {
        config.headers.token = localStorage.getItem(TOKEN_KEY) || '';
        return config;
    },
    err => Promise.reject(err),
);

instance.interceptors.response.use(
    r => r,
    err => {
        if (axios.isCancel(err)) return Promise.reject('Request was cancelled');
        if (
            {}.hasOwnProperty.call(err, 'errorHandle') &&
            err.config.errorHandle === false
        ) {
            return Promise.reject(err);
        }

        const errorStatus = err.response.status;
        const showErrorMessage = err.response.data?.showMessage ?? true;

        if (errorStatus === 401) {
            if (window.location.pathname !== '/login') {
                store.dispatch('logout');
                return;
            }

            return Promise.reject();
        }

        const message =
            err.response.data && err.response.data.message
                ? errors[err.response.data.message] || err.response.data.message
                : err.response.data;

        showErrorMessage &&
            Message({
                message,
                type: EXCEPTION_TYPES_FOR_STATUS[errorStatus] || 'error',
                duration: 6000,
            });

        return Promise.reject(err);
    },
);

export default instance;
