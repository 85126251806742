/**
 * @author feod0r
 */
import router from '@/router/router';
import api, { TOKEN_KEY } from '@/utils/api';
import { GROUP_KEY } from '@/utils/constants';

export default {
    async initialLoad({ commit, dispatch, getters }) {
        try {
            await dispatch('fetchSystemInfo');

            const groupId = localStorage.getItem(GROUP_KEY);
            await dispatch('loadGroupList');
            if (
                !groupId ||
                !getters.group.list.some(({ id }) => id === Number(groupId))
            ) {
                dispatch('logout');
                return;
            } else {
                commit('setGroupValue', Number(groupId));
            }

            await dispatch('loadPropertiesData');

            if (window.location.pathname === '/login')
                setTimeout(() => router.push({ path: '/' }), 0);
        } catch (ignored) {
            //
        } finally {
            commit('setInitialLoaded');
        }
    },
    async fetchSystemInfo({ dispatch }) {
        const {
            data: { token, user, access },
        } = await api.get('/user/info');

        await dispatch('storeAuthorization', { user, access, token });
    },
    async authorization({ dispatch }, requestData) {
        const { data } = await api
            .post('/user/authorization', requestData)
            .catch(error => {
                throw error;
            });

        if (data === 'authentication_required') return data;

        const { token, user, access } = data;

        await dispatch('storeAuthorization', { user, access, token });
    },
    async storeAuthorization({ commit }, { user, access, token }) {
        localStorage.setItem(TOKEN_KEY, token);

        commit('setAuthorized', { user, access });
    },
    logout({ commit }) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(GROUP_KEY);
        commit('logout');
    },
    async loadPropertiesData({ commit, getters }) {
        const { data: propertiesMap } = await api.get('/properties-data/', {
            params: { groupId: getters.groupId },
        });
        commit('updatePropertiesData', propertiesMap);
    },
    async loadTransactionData({ commit, getters }, page = 1) {
        commit('setIsTransactionLoading', true);
        commit('setTransactionPage', page);
        try {
            const filters = getters.transactionDataFilters;
            const body = { ...filters, groupId: getters.groupId };
            const { data } = await api.post(`/transaction/list/${page}`, body);

            commit('setTransactionData', data);
        } finally {
            commit('setIsTransactionLoading', false);
        }
    },
    async exportChecker({ getters }, { source, fileName }) {
        const filters = getters.transactionDataFilters;
        filters.groupId = getters.groupId;
        filters.source = source || null;
        const result = await api.post(`/transaction/excel/check`, {
            filters,
            fileName,
        });
        return result.data;
    },
    async exportExcel(_, { fileName }) {
        const result = await api.post(
            `/transaction/excel/export`,
            { fileName },
            { responseType: 'blob' },
        );
        return result.data;
    },
    async importExcel(_, requestData) {
        const result = await api.post(`/transaction/excel/import`, requestData);
        return result.data;
    },
    async loadMerchantBalancesData({ commit }) {
        commit('setIsMerchantBalancesLoading', true);

        try {
            const { data } = await api.get('/merchant-balances/');
            commit('setMerchantBalancesData', data);
        } finally {
            commit('setIsMerchantBalancesLoading', false);
        }
    },
    async loadGroupList({ commit }) {
        commit('setGroupListLoadStarted');
        try {
            const { data: list } = await api.get('/group');
            commit('setGroupListLoadSuccess', list);
        } catch (e) {
            commit('setGroupListLoadError');
        }
    },
    async changeGroup({ commit, dispatch }, groupId) {
        commit('setGroupValue', groupId);
        localStorage.setItem(GROUP_KEY, groupId);
        dispatch('loadPropertiesData');
    },
    async utilsConvertCurrency(_, params) {
        const result = await api.get('utils/convert-currency', { params });
        return result.data;
    },
    async utilsConvertCurrencies(_, currencies) {
        const result = await api.post('utils/convert-currencies', currencies);
        return result.data;
    },
};
